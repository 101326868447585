import config, { ProjectName } from '@/config';
import * as ajax from '@/helpers/ajax';

const fetchSearchSuggestions = async (
  query: string,
  projectName: ProjectName
): Promise<string[]> => {
  if (!query) {
    return [];
  }

  const url = new URL(`${config.apiBaseUrl}/search/suggestions`);

  url.searchParams.append('q', query);
  url.searchParams.append('projectName', projectName);

  const { results } = await ajax.getJson<{ results: string[] }>(url);

  return results;
};

export default fetchSearchSuggestions;
