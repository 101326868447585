import { getSafeInnerHTMLProp } from '@/helpers/getSafeInnerHTML';
import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import AnimatedIcon from '@uikit/components/AnimatedIcon/AnimatedIcon';
import Icon from '@uikit/components/Icon/Icon';
import Link from '@uikit/components/Link/Link';
import clsx from 'clsx';
import { useState } from 'react';
import styles from './MainHeaderMenuItem.module.scss';
import { MainHeaderMenuItemProps } from './types';

const MainHeaderMenuItem = ({
  ariaHidden,
  animatedIcon,
  icon,
  id,
  isActive = false,
  isInternalUrl = true,
  subtitle,
  tabIndex,
  title,
  url,
}: MainHeaderMenuItemProps) => {
  const [isIconPlaying, setIsIconPlaying] = useState(false);
  const isMobile = useIsMobile(BreakPoint.LG);

  const pauseIcon = () => setIsIconPlaying(false);
  const playIcon = () => setIsIconPlaying(true);

  const content = (
    <>
      {icon && (isMobile || !animatedIcon) && (
        <Icon
          aria-hidden="true"
          className={styles.icon}
          isOutlined
          size="iconSize24"
          variant={icon}
        />
      )}

      {animatedIcon && !isMobile && (
        <div aria-hidden="true" className={styles.icon}>
          <AnimatedIcon
            className={styles.animatedIcon}
            pauseMode="finishAndPause"
            playing={isIconPlaying}
            variant={animatedIcon}
          />
        </div>
      )}

      <div>
        <div className={styles.title} id={`${id}-title`}>
          {title}
        </div>
        {subtitle && (
          <div
            className={styles.subtitle}
            {...getSafeInnerHTMLProp(subtitle)}
            id={`${id}-subtitle`}
          />
        )}
      </div>
    </>
  );

  return isInternalUrl ? (
    <Link
      aria-current={isActive ? 'page' : false}
      className={clsx(styles.base, {
        [styles.isActive]: isActive,
      })}
      id={id}
      onBlur={pauseIcon}
      onFocus={playIcon}
      onMouseEnter={playIcon}
      onMouseLeave={pauseIcon}
      tabIndex={tabIndex}
      to={url}
      aria-labelledby={`${id}-title`}
      aria-describedby={subtitle ? `${id}-subtitle` : undefined}
      aria-hidden={ariaHidden}
    >
      {content}
    </Link>
  ) : (
    <a
      aria-current={isActive ? 'page' : false}
      className={clsx(styles.base, {
        [styles.isActive]: isActive,
      })}
      id={id}
      href={url}
      onBlur={pauseIcon}
      onFocus={playIcon}
      onMouseEnter={playIcon}
      onMouseLeave={pauseIcon}
      tabIndex={tabIndex}
      aria-labelledby={`${id}-title`}
      aria-describedby={subtitle ? `${id}-subtitle` : undefined}
      aria-hidden={ariaHidden}
    >
      {content}
    </a>
  );
};

export default MainHeaderMenuItem;
