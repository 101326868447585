import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import {
  MAIN_HEADER_COLLAPSIBLE,
  MAIN_HEADER_MOBILE_COLLAPSIBLE,
  PREVENT_DISMISS_PROP,
} from '@/hooks/useHeader/consts';
import { isMainHeaderMenuItem } from '@/hooks/useHeader/utils';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import useMeasure from '@/hooks/useMeasure/useMeasure';
import { useSafeLayoutEffect } from '@/hooks/useSafeLayoutEffect/useSafeLayoutEffect';
import useScroll from '@/hooks/useScroll/useScroll';
import Clickable from '@uikit/components/Clickable/Clickable';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import FocusTrap from '@uikit/components/FocusTrap/FocusTrap';
import Hamburger from '@uikit/components/Hamburger/Hamburger';
import Icon from '@uikit/components/Icon/Icon';
import LockBodyScroll from '@uikit/components/LockBodyScroll/LockBodyScroll';
import toID from '@uikit/helpers/toID';
import clsx from 'clsx';
import {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import styles from './MainHeader.module.scss';
import MainHeaderAddon from './MainHeaderAddon';
import MainHeaderMenuItem from './MainHeaderMenuItem';
import { MainHeaderProps } from './types';

const MOBILE_MENU_CLOSING = '%CLOSING';
const MOBILE_MENU_ROOT = '%ROOT';

const getIdForTitle = (title: string) =>
  toID(title, { maxLength: -1, replaceSpacesWith: '-' });

const getMobileMenuClosingId = (id: string) => `${id}${MOBILE_MENU_CLOSING}`;
const getMobileMenuIds = (id: string) => [id, `${id}${MOBILE_MENU_CLOSING}`];

const MainHeader = forwardRef<HTMLElement, MainHeaderProps>((props, ref) => {
  const {
    audiences = [],
    hasActiveCollapsible,
    isFullWidth = false,
    items,
    layout = 'default',
    logoSlot,
    menuOffset = 0,
    onCollapsibleToggle,
    preHeaderLinks = [],
    renderAddons,
    shouldBeInverted = false,
    shouldBeSticky = false,
    shouldCollapseUntilXL = false,
    shouldHaveBackground = false,
    shouldHideContent = false,
  } = props;

  const [activeMenu, setActiveMenu] = useState<string>();

  const [mobileMenuOffset, setMobileMenuOffset] = useState(0);
  const [shouldBeTranslucent, setShouldBeTranslucent] = useState(false);

  const isMobile = useIsMobile(
    shouldCollapseUntilXL ? BreakPoint.XL : BreakPoint.LG
  );
  const isMobileMenuActive = hasActiveCollapsible && isMobile;

  const menuRef = useRef<HTMLElement>(null);
  const mobileMenuRef = useRef<HTMLElement>(null);
  const mobileMenuMeasurement = useMeasure(mobileMenuRef, true);

  useImperativeHandle<HTMLElement | null, HTMLElement | null>(
    ref,
    () => menuRef.current
  );

  useScroll(() => {
    if (!isMobile && activeMenu !== undefined) {
      onCollapsibleToggle(MAIN_HEADER_COLLAPSIBLE);
      setActiveMenu(undefined);
    }

    if (mobileMenuRef.current) {
      const { top } = mobileMenuRef.current.getBoundingClientRect();
      setMobileMenuOffset(top);
    }

    if (shouldBeSticky && menuRef.current) {
      const { top } = menuRef.current.getBoundingClientRect();
      setShouldBeTranslucent(Math.floor(top) <= 0);
    }
  });

  useSafeLayoutEffect(() => {
    if (mobileMenuRef.current) {
      const { top } = mobileMenuRef.current.getBoundingClientRect();
      setMobileMenuOffset(top);
    }
  }, [mobileMenuRef]);

  useSafeLayoutEffect(() => {
    setMobileMenuOffset(mobileMenuMeasurement.top);
  }, [mobileMenuMeasurement]);

  useEffect(() => {
    if (
      !hasActiveCollapsible &&
      activeMenu !== undefined &&
      !activeMenu.endsWith(MOBILE_MENU_CLOSING)
    ) {
      setActiveMenu(undefined);
    }
    // Next line is required or otherwise the menu would never open again.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasActiveCollapsible]);

  const menuStyle = {
    '--offset': `${menuOffset}px`,
  } as CSSProperties;

  const mobileMenuStyle = {
    '--offset': `${mobileMenuOffset}px`,
  } as CSSProperties;

  const getMenuTrigger = (menu: string) =>
    document.getElementById(`main-header-menu-${getIdForTitle(menu)}-trigger`);

  const handleOnBackItemClicked = () => {
    setActiveMenu(MOBILE_MENU_ROOT);
  };

  const handleOnHamburgerClick = () => {
    onCollapsibleToggle(MAIN_HEADER_MOBILE_COLLAPSIBLE);

    if (activeMenu === undefined) {
      setActiveMenu(MOBILE_MENU_ROOT);
    } else if (activeMenu === MOBILE_MENU_ROOT) {
      setActiveMenu(undefined);
    } else {
      setActiveMenu(getMobileMenuClosingId(activeMenu));
      setTimeout(() => setActiveMenu(undefined), 250);
    }
  };

  const handleOnItemClick = (item: string, fromKeyboard: boolean = false) => {
    if (activeMenu === item) {
      !isMobile && onCollapsibleToggle(MAIN_HEADER_COLLAPSIBLE);
      setActiveMenu(undefined);
    } else if (activeMenu === undefined) {
      onCollapsibleToggle(MAIN_HEADER_COLLAPSIBLE);
      setActiveMenu(item);

      fromKeyboard &&
        document
          .getElementById(`main-header-menu-${getIdForTitle(item)}-item-0`)
          ?.focus();
    } else if (isMobile) {
      setActiveMenu(item);
    } else {
      setActiveMenu(undefined);
      setTimeout(() => setActiveMenu(item), 500);
    }
  };

  const mainHeaderItems = items.filter(isMainHeaderMenuItem);

  const hasAnyItems = items.length + preHeaderLinks.length > 0;

  return (
    <div
      className={clsx(styles.container, {
        [styles.hasCareerLayout]: layout === 'career',
        [styles.isFullWidth]: isFullWidth,
        [styles.isInverted]:
          shouldBeInverted &&
          !isMobileMenuActive &&
          !shouldBeTranslucent &&
          !shouldHaveBackground,
        [styles.isTranslucent]:
          shouldBeTranslucent &&
          !isMobileMenuActive &&
          !shouldHaveBackground &&
          !shouldHideContent,
      })}
      data-collapse-until-xl={shouldCollapseUntilXL}
    >
      {isMobileMenuActive && <LockBodyScroll />}

      {!isMobile && mainHeaderItems.length > 0 && (
        <aside className={styles.menuHost}>
          {mainHeaderItems.map((item) => {
            const isActive = hasActiveCollapsible && activeMenu === item.title;

            return (
              <menu
                aria-hidden={!isActive}
                className={clsx(styles.menu, {
                  [styles.isActive]: isActive,
                })}
                id={`main-header-menu-${getIdForTitle(item.title)}`}
                style={menuStyle}
                key={item.title}
                {...PREVENT_DISMISS_PROP}
              >
                <FocusTrap
                  behavior="refocus"
                  isActive={activeMenu === item.title}
                  target={getMenuTrigger(item.title)}
                />

                {item.links.map((link, index) => (
                  <MainHeaderMenuItem
                    {...link}
                    id={`main-header-menu-${getIdForTitle(
                      item.title
                    )}-item-${index}`}
                    tabIndex={activeMenu === item.title ? 0 : -1}
                    key={link.title}
                    ariaHidden={!isActive}
                  />
                ))}

                <FocusTrap
                  behavior="refocus"
                  isActive={activeMenu === item.title}
                  target={getMenuTrigger(item.title)}
                />
              </menu>
            );
          })}
        </aside>
      )}

      <nav
        className={clsx(styles.base, {
          [styles.isInverted]:
            shouldBeInverted && !shouldBeTranslucent && !shouldHaveBackground,
          [styles.isTranslucent]:
            shouldBeTranslucent &&
            !isFullWidth &&
            !shouldHaveBackground &&
            !shouldHideContent,
        })}
        ref={menuRef}
      >
        <div
          aria-hidden={isMobile && shouldHideContent}
          className={clsx(styles.logo, {
            [styles.isHidden]: isMobile && shouldHideContent,
          })}
        >
          {logoSlot}
        </div>

        <div
          aria-hidden={shouldHideContent}
          className={clsx(styles.items, {
            [styles.isHidden]: shouldHideContent,
          })}
        >
          {items.map((item) => {
            if ('links' in item) {
              return (
                <button
                  aria-controls={`main-header-menu-${getIdForTitle(
                    item.title
                  )}`}
                  aria-expanded={
                    hasActiveCollapsible && activeMenu === item.title
                  }
                  aria-label={`${item.title}-Menü ${
                    hasActiveCollapsible && activeMenu === item.title
                      ? 'schließen'
                      : 'öffnen'
                  }`}
                  className={clsx(styles.item, {
                    [styles.isActive]:
                      hasActiveCollapsible && activeMenu === item.title,
                  })}
                  id={`main-header-menu-${getIdForTitle(item.title)}-trigger`}
                  key={item.title}
                  onClick={(ev) =>
                    handleOnItemClick(item.title, ev.detail === 0)
                  }
                  {...PREVENT_DISMISS_PROP}
                >
                  {item.icon && (
                    <Icon
                      aria-hidden="true"
                      className={styles.itemIcon}
                      size="iconSize20"
                      variant={item.icon}
                    />
                  )}
                  <span>{item.title}</span>
                  <Icon
                    aria-hidden="true"
                    className={styles.itemChevron}
                    size="iconSize20"
                    variant="action/chevron-down"
                  />
                </button>
              );
            }

            return (
              <Clickable
                className={styles.item}
                key={item.title}
                {...(item.isInternalUrl
                  ? {
                      actionType: ClickableAction.Linkintern,
                      linkintern: item.url,
                    }
                  : {
                      actionType: ClickableAction.Linkextern,
                      linkextern: item.url,
                    })}
              >
                {item.icon && (
                  <Icon
                    aria-hidden="true"
                    className={styles.itemIcon}
                    size="iconSize20"
                    variant={item.icon}
                  />
                )}
                <span>{item.title}</span>
              </Clickable>
            );
          })}
        </div>

        <div className={styles.addons}>
          {renderAddons?.({
            isInverted: shouldBeInverted && !shouldHaveBackground,
            isTranslucent: shouldBeTranslucent,
            mobileContentOffset: mobileMenuOffset,
            shouldHideContent,
          })}

          {isMobile && hasAnyItems && (
            <button
              aria-controls="main-header-mobile-menu"
              aria-expanded={isMobileMenuActive}
              aria-hidden={shouldHideContent}
              aria-label={isMobileMenuActive ? 'Menü schließen' : 'Menü öffnen'}
              className={clsx(styles.addonButton, styles.mobileMenuToggle, {
                [styles.isActive]:
                  hasActiveCollapsible && activeMenu !== undefined,
                [styles.isHidden]: shouldHideContent,
              })}
              onClick={handleOnHamburgerClick}
              {...PREVENT_DISMISS_PROP}
            >
              <div className={styles.addonButtonIcon}>
                <Hamburger crossed={isMobileMenuActive} />
              </div>
              <span>Menü</span>
            </button>
          )}
        </div>
      </nav>

      {isMobile && hasAnyItems && (
        <main
          aria-hidden={!isMobileMenuActive}
          className={clsx(styles.mobileMenuHost, {
            [styles.isActive]: isMobileMenuActive,
          })}
          id="main-header-mobile-menu"
          ref={mobileMenuRef}
          style={mobileMenuStyle}
          {...PREVENT_DISMISS_PROP}
        >
          <menu
            aria-hidden={![MOBILE_MENU_ROOT, undefined].includes(activeMenu)}
            className={clsx(styles.mobileMenuPage, styles.isRoot, {
              [styles.isActive]: [MOBILE_MENU_ROOT, undefined].includes(
                activeMenu
              ),
            })}
          >
            {audiences.length > 0 && (
              <div className={styles.mobileMenuAudiences}>
                {audiences.map(({ isActive, title, url }) => (
                  <a
                    className={clsx(styles.mobileMenuAudienceLink, {
                      [styles.isActive]: isActive,
                    })}
                    href={url}
                    key={url}
                    tabIndex={isMobileMenuActive ? 0 : -1}
                  >
                    {title}
                  </a>
                ))}
              </div>
            )}

            <div className={styles.mobileMenuItems}>
              {items.map((item) => {
                if ('links' in item) {
                  return (
                    <button
                      aria-label={`Zum ${item.title}-Menü`}
                      className={clsx(styles.mobileMenuItem, {
                        [styles.isActive]: activeMenu === item.title,
                      })}
                      key={item.title}
                      onClick={() => handleOnItemClick(item.title)}
                      tabIndex={isMobileMenuActive ? 0 : -1}
                    >
                      {item.icon && (
                        <Icon
                          aria-hidden="true"
                          className={styles.mobileMenuItemIcon}
                          size="iconSize20"
                          variant={item.icon}
                        />
                      )}
                      <span>{item.title}</span>
                      <Icon
                        className={styles.mobileMenuItemChevron}
                        size="iconSize24"
                        variant="action/chevron-right"
                      />
                    </button>
                  );
                }

                return (
                  <Clickable
                    className={styles.mobileMenuItem}
                    key={item.title}
                    tabIndex={isMobileMenuActive ? 0 : -1}
                    {...(item.isInternalUrl
                      ? {
                          actionType: ClickableAction.Linkintern,
                          linkintern: item.url,
                        }
                      : {
                          actionType: ClickableAction.Linkextern,
                          linkextern: item.url,
                        })}
                  >
                    {item.icon && (
                      <Icon
                        aria-hidden="true"
                        className={styles.mobileMenuItemIcon}
                        size="iconSize20"
                        variant={item.icon}
                      />
                    )}
                    <span>{item.title}</span>
                  </Clickable>
                );
              })}
            </div>

            {preHeaderLinks.length > 0 && (
              <div className={styles.mobileMenuLinks}>
                {preHeaderLinks.map(({ title, url }) => (
                  <a
                    className={styles.mobileMenuLink}
                    href={url}
                    key={url}
                    tabIndex={isMobileMenuActive ? 0 : -1}
                  >
                    {title}
                  </a>
                ))}
              </div>
            )}
          </menu>

          {items.filter(isMainHeaderMenuItem).map((item) => (
            <menu
              aria-hidden={
                !(
                  activeMenu &&
                  getMobileMenuIds(item.title).includes(activeMenu)
                )
              }
              className={clsx(styles.mobileMenuPage, {
                [styles.isActive]:
                  activeMenu &&
                  getMobileMenuIds(item.title).includes(activeMenu),
              })}
              key={item.title}
            >
              <button
                aria-label="Zurück zur Übersicht"
                className={styles.mobileMenuBackItem}
                onClick={handleOnBackItemClicked}
                tabIndex={isMobileMenuActive ? 0 : -1}
              >
                <Icon
                  aria-hidden="true"
                  size="iconSize24"
                  variant="action/chevron-left"
                />
                <span>{item.title}</span>
              </button>

              <div className={styles.mobileMenuItems}>
                {item.links.map((link, index) => (
                  <MainHeaderMenuItem
                    {...link}
                    id={`main-header-mobile-menu-${getIdForTitle(
                      item.title
                    )}-item-${index}`}
                    key={link.title}
                    tabIndex={
                      activeMenu &&
                      getMobileMenuIds(item.title).includes(activeMenu)
                        ? 0
                        : -1
                    }
                  />
                ))}
              </div>
            </menu>
          ))}
        </main>
      )}
    </div>
  );
});

MainHeader.displayName = 'MainHeader';

export default Object.assign(MainHeader, {
  Addon: MainHeaderAddon,
  MenuItem: MainHeaderMenuItem,
});
